<template>
<div>
  <GeneratePw />
  <BodyContent />
  <Guide />    
</div>
</template>

<script>

import BodyContent from './BodyContent.vue'
import GeneratePw from './GeneratePw.vue'
import Guide from './Guide.vue'


export default {
  
  data (){
    return {
      
    }
  },  
  components: {
    BodyContent,
    GeneratePw, 
    Guide,
    
  },
}

</script>


