<template id="navBar">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
         <a class="navbar-brand">
           <router-link class="nav-link" to="/">
            <img src="favicon.png" width="30" height="30" alt="Navigation Logo">
            </router-link>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" v-on:click="toggleNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" v-bind:class="{ 'show': show }">
            <ul id="menu-bar" class="navbar-nav mr-auto">
                <li class="nav-item ">
                     <router-link class="nav-link" to="/">Home</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/about">Über Passwörter</router-link>
                    <!--<span class="sr-only">(current)</span>-->
                </li>
             
               
            </ul>
          
        </div>
    </nav>
</template>

<script>
export default {
  
  props: {
    msg: String
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleNavbar() {
      this.show = !this.show;
    }
  }
}
</script>
<style> 
    a.nav-link  {
        color: white !important;
        font-weight: bold;
    }
    #menu-bar{
        margin-right: 0px !important;
        
    }
    a.nav-link.router-link-exact-active.router-link-active{
        /*background-color: white;*/
        text-decoration: underline;
        
    }
    a:hover{
        text-decoration: underline !important;
    }
    a.nav-link.router-link-exact-active.router-link-active{
        text-decoration: underline !important;
    }
    li{
      margin-left: auto;
    }
   
</style>