<template>
    <div class="container">
        <div id="blog" class="row">
            <div class="col-md-8">
                <div class="card textblock">
                    <h3>Was macht ein sicheres Passwort aus?</h3>
                    <ul class="blog-text">
                        <li class="text-list" v-for="tipp in tipps" v-html="tipp.text" v-bind:key="tipp">
                            {{tipp.text}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8">
                <div class="card textblock">
                    <h3>Wie kommen Hacker an Passwörter?</h3>
                    <ul class="blog-text">
                        <li class="text-list" v-for="hint in hints" v-html="hint.text" v-bind:key="hint">
                            {{hint.text}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-8">
                
            <div class="card textblock">
                <h3>die 10 häufigsten Passwörter</h3>
                <div class="pw-list">
                    <div class="pw-site left-side">
                        <ul class="blog-text">
                            <li class="text-list" v-for="left in lefts" v-bind:key="left"> 
                                {{left.text}}
                            </li>
                        </ul>
                    </div>
                    <div class="pw-site right-side">
                        <ul class="blog-text">
                            <li class="text-list" v-for="right in rights" v-bind:key="right">
                                {{right.text}}
                            </li>
                        </ul>
                    </div>
                </div>  
                
            </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tipps',

    data (){
        return{
            tipps: [
                { text: '- Ein sicheres Passwort sollte mindestens 8 Zeichen lang sein' },
                { text: '- Je Komplexer ein Passwort, desto sicherer ist das Passwort' },
                { text: '- Nimm neben Buchstaben am besten auch Zahlen und Sonderzeichen' },
                { text: '- Verwende ein Passwort niemals 2 mal' },
            ],
            hints: [
                { text: '- Durch <a class="list-link" href="https://de.wikipedia.org/wiki/Brute-Force-Methode" target="_blank">&nbsp;Bruteforce Attacken&nbsp;</a>oder&nbsp;<a class="list-link" href="https://de.wikipedia.org/wiki/W%C3%B6rterbuchangriff" target="_blank"> Dictionary Attacken</a>' },
                { text: '- Abgreifen des Passwortes direkt beim User mittels &nbsp;<a class="list-link" href="" target="_blank">Phishing</a>' },
                { text: '- Gehackte Datenbanken von Firmen, so landen deine Daten im Netz' }
            ],
            lefts: [
                { text: '123456' },
                { text: 'password' },
                { text: '123456789' },
                { text: '12345678' },
                { text: '12345' },
            ],
            rights: [
                { text: '111111' },
                { text: '1234567' },
                { text: 'sunshine' },
                { text: 'qwertz' },
                { text: 'iloveyou' },
            ]
        }
    }
}
</script>

<style>
.list-link{
    color: #007bff !important;
}
h3{
    margin-left: 10px;
    text-align: left;
}
#blog{
    display: flex;
    justify-content: center;
}
.card.textblock{
    margin-left: auto;
    margin-right: auto;
    min-height: 180px;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.text-list{
    display:flex !important;
    padding: 10px;
}
.blog-text{
    margin-left: 0px;
}
.pw-list{
    display: flex;
    flex-direction: row;
}
.pw-site{
    width: 50%;
}
@media only screen and (max-width:992px) {
    #blog{
        /*background-color: black;*/
    }
    .blog-text{
        padding-left: 0px;
    }
    .text-list{
        text-align: left ;
        display: block !important;
    }
}

</style>