<template>
  <div id="app">
      <navigation />
      <Header />
      <router-view/>
       
    <Footer />
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  
  data (){
    return {
      
    }
  },  
  components: {
    Navigation,
    Header,
    Footer
  },
  methods: {

    create: function(){
      alert('Hallo ' + this.name)
      // alert(event.target.tagName)
    //git test
    }
  },  
 
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;

}

@media only screen and (max-width:500px) {
        #app{
          overflow: hidden;
        }
    }
</style>
