<template>
    <div class="container">
        <div class="row">
            <div class="generate card col-md-12">
                <div class="passwort">
                    <span id="passwort" class="passwort-span">{{ passwort }}</span>
                </div>
                <button id="copy-btn" class="btn btn-success copy-btn" v-on:click="copyToClip">Kopieren</button>
                <div class="select-options">
                    <div class="option-group">
                        <div class="option form-check">
                            <input type="checkbox" class="form-check-input" id="lower" value="lower" v-model="checkedSort">
                            <label class="form-check-label" for="lower">a-z</label>
                        </div>
                        <div class="option form-check">
                            <input type="checkbox" class="form-check-input" id="upper" value="upper" v-model="checkedSort">
                            <label class="form-check-label" for="upper">A-Z</label>
                        </div>
                        <div class="option form-check">
                            <input type="checkbox" class="form-check-input" id="nummeric" value="nummeric" v-model="checkedSort">
                            <label class="form-check-label" for="nummeric">0-9</label>
                        </div>
                        <div class="option form-check">
                            <input type="checkbox" class="form-check-input" id="specialChar" value="specialChar" v-model="checkedSort">
                            <label class="form-check-label" for="specialChar">Sonderzeichen</label>
                        </div>
                        <div class="option">
                            {{ value }}
                        </div>
                    </div>
                    <div id="slider">
                        <vue-slider ref="slider" v-model="value" v-bind="options"></vue-slider>
                        <h1></h1>
                    </div>
                    <button class="btn btn-secondary" v-on:click="getOptions">Generiere dein Passwort</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
//import { constants } from 'crypto';


export default {
    components:{
        VueSlider
    },
    data(){
        return{
                checkedSort:[],
                value:12,
                passwort:"",
                options: 
                {
                    dotSize: 14,
                    width: 'auto',
                    height: 4,
                    contained: false,
                    direction: 'ltr',
                    data: null,
                    min: 0,
                    max: 50,
                    interval: 1,
                    disabled: false,
                    clickable: true,
                    duration: 0.5,
                    adsorb: false,
                    lazy: false,
                    tooltip: 'focus',
                    tooltipPlacement: 'top',
                    tooltipFormatter: void 0,
                    useKeyboard: false,
                    enableCross: true,
                    fixed: false,
                    minRange: void 0,
                    maxRange: void 0,
                    order: true,
                    marks: false,
                    dotOptions: void 0,
                    process: true,
                    dotStyle: void 0,
                    railStyle: void 0,
                    processStyle: void 0,
                    tooltipStyle: void 0,
                    stepStyle: void 0,
                    stepActiveStyle: void 0,
                    labelStyle: void 0,
                    labelActiveStyle: void 0,
                }
            }
        },
    methods:{
        
        getOptions: function(){
            this.passwort = "";
            const lower = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
            const upper = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
            const nummeric = ['0','1','2','3','4','5','6','7','8','9'];
            const specialChar = ['#','/','+','-','<','>','*','_',';','!','%']
            const options = this.checkedSort;
            
          
            const length = this.value;
            const pw = [];
            let j;
            for (j = 0; j < options.length; j++){
                if (options[j] == 'lower')
                    this.createArray(pw, lower);
                if (options[j] == 'upper')
                    this.createArray(pw, upper);
                if (options[j] == 'nummeric')
                    this.createArray(pw, nummeric);
                if (options[j] == 'specialChar')
                    this.createArray(pw, specialChar);        
            }
            
            let charNumber;

            for (let i = 0; i < length; i++){
                charNumber = Math.floor(Math.random()*(pw.length));
                this.passwort += pw[charNumber];
                if (this.passwort.includes('undefined')){
                    this.passwort="You must choose something..";
                }
            }
        },
        
        createArray: function createArray(pw, array){            
            for (let x = 0; x < array.length; x++) {
                pw.push(array[x]);
            }
        },
        copyToClip: function(){
            var el = document.createElement('textarea');
            el.value = this.passwort;
            el.setAttribute('readonly', '');
            el.style = {position: 'absolute', left: '-9999px'};
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }


    }
}
</script>
<style>
#passwort{
    word-break: break-word;
}
.generate{
    display: flex;
    justify-content: center;
    flex-direction: column;
    /*width: 70%;*/
    padding-top: 20px;
    padding-bottom: 20px;
}
.passwort{
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right: auto;
    margin-left: auto;
    height: 40px;
    width: 90%;
    background-color: lightskyblue;
}
.option-group{
    display: flex;
    width: 400px;
    
    margin-left: auto;
    margin-right: auto;
    
}   

.option{
    padding: 15px;
}
#copy-btn{
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    /*width: 50px;*/
}
#slider{
    margin-left: auto;
    margin-right: auto;
    width: 400px;
  
}
@media only screen and (max-width:500px) { 
    .generate{
        margin-left: 20px;
        margin-right: 20px;
    }
    .option-group{
        width: auto;
        flex-direction: column;

    }
    .option{
        padding: 5px;
    }
    #slider {
        width: auto;
    }
    .row{
        display: flex;
        justify-content:center;
        align-items:center;
    }

}
</style>
