import Vue from 'vue';
import VueRouter from 'vue-router';
import BootstrapVue from "bootstrap-vue";
import App from './App.vue';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";


import routes from './routes';
//Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(VueRouter);

//const router = new VueRouter({ routes });

const router = new VueRouter({
  routes,
  mode: "history"
});
 
new Vue({
  router,
  render: h => h(App),
}).$mount('#app');


