<template>
    <footer>
        <div id="footer">
            <div class="footer-content">
                <div class="container">
                    <div class="row">
                        <ul>
                            <li class="mobile">|</li>
                            <li><a>© 2019 Dennis Schaffer · Alle Rechte vorbehalten</a></li>
                            <li class="mobile">|</li>
                            <li><a href="mailto:dschaffer@password-organizer.de">
                                Kontakt: dschaffer@password-organizer.de</a></li>
                            <li class="mobile">|</li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  
</template>
<script>
export default {
  


}
</script>
<style>
    #footer{
        /*border-top: 2px solid lightskyblue;*/
        background-color: #343a40!important;
        margin-left: auto;
        margin-right: auto;
        height: 60px;
        max-width: 100%;
        margin-top: 30px;
        position: relative;
        /*position: fixed;
        left: 0;
        right: 0;
        bottom: 0;*/
    }
    .footer-content{
        margin-top: 10px;
    }
    .footer-content>.container{
        padding-top: 10px
    }
    a{
        color: white !important;
        
    }
    a:hover{
            text-decoration:none !important;
            border: none !important;
        }
    ul{
        margin-left: auto;
        margin-right: auto;
    }
    li {
        padding-right:10px;
        padding-left: 10px;
        display: inline;
        list-style-type: none;
    }   
    .end{
        display: flex;
    }
@media only screen and (max-width:500px) { 
    #footer{
        height: auto;
        position: fixed;
        bottom: 0;

    }
    ul{
        margin-left: -25px;
    }
    li{
        padding-left: 0px;
        padding-right: 0px;
        display: grid;
    }
    .mobile{
        display: none;
    }
}
@media only screen and (max-width:1031px) { 
    #footer{
        height: auto;
        position: relative;
        bottom: 0;
    }
}
</style>
