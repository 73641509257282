<template>
<div>
  <Tipps />
</div>
</template>


<script>
import Tipps from './Tipps.vue'

export default {
  data (){
    return{
      
    }
  },
  components: {
    Tipps,
  }
}
</script>

