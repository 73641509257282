<template>

<div class="info-modal-button">
  <b-button v-b-modal.modal-info>Anleitung</b-button>

  <b-modal id="modal-info" title="Wie funktionierts?" hide-footer>
    <div>
    <p>Mit diesem Tool kannst du dir mit ein paar Mausklicks ein sicheres Passwort erstellen lassen.</p>
    <p>Zuerst wählst du aus den Einstellungen einfach aus welche Elemente du in deinem Passwort haben möchtest.</p>
    <p>Danach kannst du mit Hilfe des blauen Reglers auswählen aus wie vielen Zeichen dein Passwort bestehen soll</p>
    <p>Mit Klick auf "Generieren" wird dein Passwort erstellt und du kannst es mit Hilfe des grünen Buttons kopiern</p>
    <p>Jetzt kannst du es überall einfügen.</p>
    </div>
  </b-modal>
</div>

</template>

<script>
export default {
    props:{
        msg: String
    },
    data() {
        return{
            show: false
        }
    },
    methods: {
        modalCollapse(){
            this.show = !this.show;
        }
    }
}
</script>
<style scoped>
.info-modal-button{
    display: flex;
    margin-left: 30px;
}
</style>